exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-clouds-backblaze-b-2-ts": () => import("./../../../src/pages/clouds/backblaze-b2.ts" /* webpackChunkName: "component---src-pages-clouds-backblaze-b-2-ts" */),
  "component---src-pages-clouds-idrive-e-2-ts": () => import("./../../../src/pages/clouds/idrive-e2.ts" /* webpackChunkName: "component---src-pages-clouds-idrive-e-2-ts" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-migration-tsx": () => import("./../../../src/pages/migration.tsx" /* webpackChunkName: "component---src-pages-migration-tsx" */),
  "component---src-pages-multi-cloud-tsx": () => import("./../../../src/pages/multi-cloud.tsx" /* webpackChunkName: "component---src-pages-multi-cloud-tsx" */),
  "component---src-pages-s-3-azure-tsx": () => import("./../../../src/pages/s3-azure.tsx" /* webpackChunkName: "component---src-pages-s-3-azure-tsx" */),
  "component---src-templates-md-template-tsx": () => import("./../../../src/templates/md-template.tsx" /* webpackChunkName: "component---src-templates-md-template-tsx" */)
}

