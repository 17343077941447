import './src/styles/main.scss';

export const onRouteUpdate = ({location}) => {
  if (!location.hash) {
    return;
  }

  const block = document.getElementById(location.hash.slice(1));

  if (block) {
    setTimeout(() => {
      block.scrollIntoView();
    }, 100);
  }
};
